/* importing Google Fonts - Quicksand  */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap'); 

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, Quicksand;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}